<template>
  <div class="solution">
    <div class="transform-box" ref="transform-box1">
      <img class="bg" src="@/assets/images/solution/bg1.png" alt="">
      <img class="top-icon" src="@/assets/images/solution/top-icon1.png" alt="">
      <div class="solution-title">LPG物联网一站式解决方案</div>
      <div class="solution-content">
        <p>本方案包含智能硬件采集模块、燃企LPG优气物联网管理系统、配送端、气瓶用户端、政府安监管理系统五大模块，</p>
        <p>是一款聚集LPG领域，融合新零售、采用大数据、物联网等前沿技术的一站式服务平台。解决多方痛点，为燃企提</p>
        <p>效降本，为气瓶用户提供便捷的用气体验，为政府单位提供准确有效的管理途径。</p>
      </div>
    </div>

    <transformBox>
      <img class="bg" src="@/assets/images/solution/bg2.png" alt="">
      <div style="height: 200px"></div>
      <div class="solution-title title-decorate">智能设备</div>
      <div class="solution-content">
        <p>深圳多知能源物联有限公司自主研究了接入物联网技术的气瓶泄漏报警器、称重底座、切断阀、机械手等设备构成的智能安防系统，</p>
        <p>使用户用气更安全、更高效。</p>
      </div>
    </transformBox>

    <transformBox class="display-max">
      <img class="bg" src="@/assets/images/solution/bg3.png" alt="">
      <div class="solution-content">
        <img class="bottom-icon" src="@/assets/images/solution/lpg-content.png" alt="">
      </div>
    </transformBox>

    <transformBox>
      <img class="bg" src="@/assets/images/solution/bg4.png" alt="">
      <div class="left-content">
        <div class="solution-title-left">移动配送</div>
        <div class="solution-content">
          <div class="li">配送站点、配送员工管理，优气达配送端，包含店长和配送工双角色，实现移动智能配送</div>
          <div class="li">商城管理，可对用户移动端的产品价格上架、下架管理</div>
          <div class="li">APP配送按照用户所在的地区、自动分配订单给开户的门店</div>
          <div class="li">分为店长配送系统和配送员配送系统，分工明确</div>
          <div class="li">操作简单，支持配送入户安检</div>
        </div>
      </div>
    </transformBox>

    <transformBox class="display-max">
      <img class="bg" src="@/assets/images/solution/bg5.png" alt="">
      <div class="solution-content">
        <img class="bottom-icon" src="@/assets/images/solution/bottle.png" alt="">
      </div>
    </transformBox>

    <transformBox>
      <img class="bg" src="@/assets/images/solution/bg6.png" alt="">
      <div class="solution-content">
        <img class="bottom-icon" src="@/assets/images/solution/content.png" alt="">
      </div>
    </transformBox>
    
    <!-- <div class="transform-box" :class="[scrollTop >= 1100 && 'recovery', scrollTop >= 1400 && 'recovery-child']">
      <img class="bg" src="@/assets/images/solution/bg2.png" alt="">
      <div style="height: 200px"></div>
      <div class="solution-title title-decorate">智能设备</div>
      <div class="solution-content">
        <p>深圳多知能源物联有限公司自主研究了接入物联网技术的气瓶泄漏报警器、称重底座、切断阀、机械手等设备构成的智能安防系统，</p>
        <p>使用户用气更安全、更高效。</p>
      </div>
    </div> -->
    <!-- <div class="transform-box display-max" :class="[scrollTop >= 1900 && 'recovery', scrollTop >= 2200 && 'recovery-child']">
      <img class="bg" src="@/assets/images/solution/bg3.png" alt="">
      <div class="solution-content">
        <img class="bottom-icon" src="@/assets/images/solution/lpg-content.png" alt="">
      </div>
    </div> -->
    <!-- <div class="transform-box" :class="[scrollTop >= 2600 && 'recovery', scrollTop >= 2900 && 'recovery-child']">
      <img class="bg" src="@/assets/images/solution/bg4.png" alt="">
      <div class="left-content">
        <div class="solution-title-left">移动配送</div>
        <div class="solution-content">
          <div class="li">配送站点、配送员工管理，优气达配送端，包含店长和配送工双角色，实现移动智能配送</div>
          <div class="li">商城管理，可对用户移动端的产品价格上架、下架管理</div>
          <div class="li">APP配送按照用户所在的地区、自动分配订单给开户的门店</div>
          <div class="li">分为店长配送系统和配送员配送系统，分工明确</div>
          <div class="li">操作简单，支持配送入户安检</div>
        </div>
      </div>
    </div> -->
    <!-- <div class="transform-box display-max" :class="[scrollTop >= 3500 && 'recovery', scrollTop >= 3800 && 'recovery-child']">
      <img class="bg" src="@/assets/images/solution/bg5.png" alt="">
      <div class="solution-content">
        <img class="bottom-icon" src="@/assets/images/solution/bottle.png" alt="">
      </div>
    </div> -->
    <!-- <div class="transform-box" :class="[scrollTop >= 4050 && 'recovery', scrollTop >= 4500 && 'recovery-child']">
      <img class="bg" src="@/assets/images/solution/bg6.png" alt="">
      
      <div class="solution-content">
        <img class="bottom-icon" src="@/assets/images/solution/content.png" alt="">
      </div>
    </div> -->
    <div class="content">
      <div class="content-title">智能解决方案相关产品</div>
      <swiper
        class="banner-swiper"
        :options="swiperOptions"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        :navigation="true"
      >
        <swiper-slide class="swiper-slide" v-for="(item, index) in productList" :key="index">
          <a :title="item.type" :href="'/product/detail/?id='+item.id" class="product-item">
            <div class="product-img" :style="'background-image: url('+ item.img  + ')'"></div>
            <div class="product-name">{{item.name}}</div>
            <div class="product-code">{{item.code}}</div>
          </a>
        </swiper-slide>
        <div class="swiper-button" slot="button-next">
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import transformBox from "@/components/transformBox"
import 'swiper/css/swiper.css'
import product from "@/assets/hypocritical/product.js"
const productList = []
Object.keys(product).map(key => productList.push(...product[key].list.map(it => {it.type = key;return it})))
console.log(productList,"product")
export default {
  components: {
    Swiper,
    SwiperSlide,
    transformBox
  },
  metaInfo: {
    title: "深圳多知能源物联有限公司专注智能家居,烟雾报警器,燃气报警器",
    meta: [
      {
        name: "keywords",
        content: "燃气报警器电子产业互联网,液化石油气LPG,物联网应用软硬件产品,燃企配送移动端",
      },
      {
        name: "description",
        content:
          "优气物联致力于“液化石油气LPG” 物联网应用软硬件产品的研发、生产、销售和服务。旨在为燃气企业赋能，降低成本，提升效率，为监管单位提供高效精准的监管渠道，为终端用户带来高质量的用气体验。打造一个聚焦LPG领域，融合新零售、采用大数据、物联网等前沿技术的一站式服务平台。",
      },
    ],
  },
  data(){
    return {
      scrollTop: 0,
      swiperOptions: {
        autoplay:{delay: 3600},
        loop: true,
        slidesPerView: 4,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      productList,
    }
  },
  mounted(){
    // console.log(this.$refs['transform-box0'].classList,"this.$refs['transform-box0']")
    // console.log(this.$refs['transform-box0'].__proto__,"this.$refs['transform-box0']")
    setTimeout(() => {
      this.$refs['transform-box1'].classList.add("recovery")
      this.$refs['transform-box1'].classList.add("recovery-child")
    }, 100);
    window.addEventListener("scroll", () => {
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      scrollTop += document.documentElement.clientHeight
      console.log(scrollTop)
      this.scrollTop = scrollTop
    })
  },
  methods: {
    onSwiper(){

    },
    onSlideChange(){

    }
  }
}
</script>

<style lang="scss" scoped>
.solution{
  background: #f8f8f8;
  .transform-box{
    height: 794px;
    width: 100%;
    position: relative;
    overflow: hidden;
    transition: all 1s;
    transition-timing-function: ease-out;
    top: 100px;
    opacity: 0;
    text-align: center;
    color: white;
    .bg{
      width: 1920px;
      left: calc(50% - 960px);
      position: absolute;
    }
    .top-icon{
      margin-top: 135px;
      margin-left: -100px;
      position: relative;
      opacity: 0;
    }
    .solution-title{
      margin-top: 30px;
      margin-right: -100px;
      position: relative;
      opacity: 0;
      font-size: 52px;
      color: white;
    }
    .title-decorate{
      margin-bottom: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &::before{
        content: "";
        width: 16px;
        height: 16px;
        background: #00a0ea;
        display: inline-block;
        margin-right: 20px;
        margin-top: 10px;
      }
      &::after{
        content: "";
        width: 16px;
        height: 16px;
        background: #00a0ea;
        display: inline-block;
        margin-left: 20px;
        margin-top: 10px;
      }
    }
    .solution-content{
      margin-top: 110px;
      opacity: 0;
      position: relative;
      & > p{
        color: white;
      }
      & > img{
        position: absolute;
        left: calc(50% - 940px);
      }
    }
    .bottom-icon{
      opacity: 0;
      margin-top: 16px;
      position: relative;
    }

    .left-content{
      opacity: 0;
      margin: 0 auto;
      margin-top: 100px;
      width: 1350px;
      text-align: left
    }
    .solution-title-left{
      position: relative;
      margin: 0 auto;
      margin-top: 165px;
      line-height: 78px;
      font-size: 52px;
      color: white;
      display: inline-block;
      padding: 20px 0;
      // border-bottom: 4px solid #2da0db;
      &::after{
        content: "";
        width: 16px;
        height: 16px;
        background: #00a0ea;
        display: inline-block;
        margin-left: 20px;
        margin-top: 10px;
      }
    }

    .right-content{
      opacity: 0;
      margin: 0 auto;
      margin-top: 100px;
      width: 1520px;
      text-align: right
    }




    .top-icon, .solution-title, .solution-content, .bottom-icon, .left-content, .right-content{
      transition: all 1.2s;
      transition-timing-function: ease-out;
    }
  }
  .recovery{
    top: 0;
    opacity: 1;
  }
  .recovery-child{
    .top-icon{
      margin-left: 0;
    }
    .solution-title{
      margin-right: 0;
    }
    .solution-content{
      margin-top: 16px;
      .li{
        color: white;
        display: flex;
        align-items: center;
        margin: 40px 0;
        &::before{
          content: "";
          width: 20px;
          height: 20px;
          display: inline-block;
          background: white;
          margin-right: 30px;
          transform: rotate(45deg);

        }

      }
    }
    .left-content, .right-content{
      margin-top: 0;
    }
    .top-icon, .solution-title, .solution-content, .bottom-icon, .left-content, .right-content{
      opacity: 1;
    }
  }
}
.content{
  padding-top: 100px;
  padding-bottom: 100px;
  .content-title{
    font-size: 25px;
    font-weight: 600;
    padding-bottom: 60px;
  }
  .content-subtitle{
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 50px;
  }
}
.product-item{
  width: 100%;
  padding: 0 .4vw;
  box-sizing: border-box;
  display: inline-block;
  .product-img{
    width: 100%;
    padding-bottom: 60%;
    background-position: center;
    background-size: cover;
  }
  .product-name{
    padding-top: 14px;
    background: white;
  }
  .product-code{
    padding: 7px 0;
    background: white;
  }
}

::v-deep .swiper-button{
  opacity: 0;
  .swiper-button-next, .swiper-button-prev{
    &::after{
      color: #858585;
    }
  }
}
.swiper-container:hover{
  ::v-deep .swiper-button{
    opacity: 1;
  }
}
@media screen and (max-width: 1600px) {
  .display-max{
    height: auto!important;
    .bg{
      width: 100%!important;
      left:0px!important;
    }
    .solution-content{
      & > img{
        position: relative!important;
        width: 100%;
        margin: 0!important;
        left:0!important;
      }
    }
  }

}
</style>