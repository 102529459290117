<template>
  <div :class="'transform-box '+ classList" ref="transformBox">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data(){
    return {
      classList: ""
    }
  },
  mounted(){
    window.addEventListener("scroll", () => {
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      scrollTop += document.documentElement.clientHeight
      
      if(this.$refs.transformBox.offsetTop <= scrollTop - 200){
        this.classList = this.$refs.transformBox.offsetTop <= scrollTop - 600 ? "recovery recovery-child" : "recovery"
      }else{
        this.classList = ""
      }
    })
  }

}
</script>

<style>

</style>